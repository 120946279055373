/* ==========================================================================
   Utilities
   ========================================================================== */

/* Font smoothing
   ========================================================================== */

/**
 * Antialiased font smoothing works best for light text on a dark background.
 * Apply to single elements instead of globally to body.
 * Note this only applies to webkit-based desktop browsers and Firefox 25 (and later) on the Mac.
 */

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Truncated text
   ========================================================================== */

.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Hidden content
   ========================================================================== */

/* Hide from both screenreaders and browsers */

.hidden {
  display: none !important;
  visibility: hidden;
}

/* Hide only visually, but have it available for screenreaders */

.visually-hidden {
  border: 0 none;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/**
 * Extends the .visually-hidden class to allow the element
 * to be focusable when navigated to via the keyboard
 */

.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* Undo visually-hidden */

.visually-hidden-undo {
  position: inherit;
  overflow: visible;
  height: auto;
  width: auto;
  margin: auto;
}

/* Hide visually and from screenreaders, but maintain layout */

.invisible {
  visibility: hidden;
}


/* Unscrollable
   ========================================================================== */

.unscrollable-y {
  overflow-y: hidden;
}

.unscrollable-x {
  overflow-x: hidden;
}


/* Clearfix
   ========================================================================== */

.clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}


/* Disabled
   ========================================================================== */

.disabled {
  opacity: 0.48;
  pointer-events: none;
  cursor: not-allowed;
}

.visually-disabled {
  &, &:hover, &:active, &:focus {
    opacity: 0.48 !important;
    cursor: not-allowed;
  }
}