/* ==========================================================================
   Animation
   ========================================================================== */

/* Page
   ========================================================================== */

@keyframes reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translate(100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translate(-100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}


/* Spin clockwise
   ========================================================================== */

@keyframes spin-cw {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* Spin counter-clockwise
   ========================================================================== */

@keyframes spin-ccw {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}


/* Pulse
   ========================================================================== */

/**
 * Usage: animation: pulse 0.8s ease 0s infinite alternate;
 */

@keyframes pulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Pop in
   ========================================================================== */

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  90% {
    opacity: 1;
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}


/* Shake
   ========================================================================== */

 @keyframes shake {
  8%, 41% {
    transform: translateX(-0.5rem);
  }

  25%, 58% {
    transform: translateX(0.5rem);
  }

  75% {
    transform: translateX(-0.25rem);
  }

  92% {
    transform: translateX(0.25rem);
  }

  0%, 100% {
    transform: translateX(0);
  }
}
