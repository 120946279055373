/* ==========================================================================
   Explore: global
   ========================================================================== */

.inpage--explore {
  .inpage__header,
  .inpage__body > *:not(.device-message) {
    @include media(large-down) {
      display: none;
    }
  }
}

.exp-change-button {
  @extend .button, .button--small, .button--primary-plain, .button--text-hidden;

  /* Animation */
  animation: slide-in-right 0.64s ease-in-out 0s 1;

  &::before {
    @extend %collecticon-arrow-left-right;
  }
}
