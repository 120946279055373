$input-range-font-family: $base-font-family !default;
$input-range-primary-color: $link-color !default;
$input-range-neutral-color: #aaaaaa !default;
$input-range-neutral-light-color: #eeeeee !default;
$input-range-disabled-color: #cccccc !default;

// input-range-slider
$input-range-slider-background: $input-range-primary-color !default;
$input-range-slider-border: 0 !default;
$input-range-slider-focus-box-shadow-radius: 5px !default;
$input-range-slider-focus-box-shadow-color: transparentize($input-range-slider-background, 0.8) !default;
$input-range-slider-height: 1.25rem !default;
$input-range-slider-width: 1.25rem !default;
$input-range-slider-transition: transform 0.3s ease-out, box-shadow 0.3s ease-out !default;
$input-range-slider-container-transition: left 0.3s ease-out !default;
$input-range-slider-active-transform: scale(1.3) !default;
$input-range-slider-disabled-background: $input-range-disabled-color !default;
$input-range-slider-disabled-border: 1px solid $input-range-disabled-color !default;

// input-range-label
$input-range-label-color: rgba($base-font-color, 0.64) !default;
$input-range-label-font-size: 0.75rem !default;
$input-range-label-position-bottom: -0.25rem !default;
$input-range-label-value-position-top: -2rem !default;

// input-range-track
$input-range-track-background: rgba($base-color, 0.24) !default;
$input-range-track-height: 0.5rem !default;
$input-range-track-transition: left 0.3s ease-out, width 0.3s ease-out !default;
$input-range-track-active-background: $input-range-primary-color !default;
$input-range-track-disabled-background: $input-range-neutral-light-color !default;
