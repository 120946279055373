/* ==========================================================================
   Toolbar
   ========================================================================== */

.toolbar,
.toolbar__menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > * {
    margin: 0;
  }

  > *:not(:last-child) {
    margin-right: $global-spacing / 4;
  }

  .button {
    vertical-align: top;
  }
}

.toolbar__label {
  @extend .heading-alt;
  font-size: 0.875rem;
  line-height: 1rem;
  display: inline-flex;
  margin: 0 ($global-spacing / 2) 0 0;
  color: rgba($base-font-color, 0.64);

  &:not(:first-child) {
    margin-left: $global-spacing;
  }
}

.toolbar__menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.toolbar__menu-item--sep:not(:last-child) {
  margin-right: $global-spacing / 2;
  padding-right: $global-spacing / 2;
  box-shadow: inset (-$base-border-width) 0 0 $base-alpha-color;
}

/**
 * "tba-" prefix stands for "toolbar action"
 */

[class^="tba-"],
[class*=" tba-"] {
  @extend .button, .button--base-plain;
}

.tba--text-hidden {
  @extend .button--text-hidden;
}

.tba-ellipsis::before {
  @extend %collecticon-ellipsis-vertical;
}

.tba-download::before {
  @extend %collecticon-download-2;
}

.tba-question::before {
  @extend %collecticon-circle-question;
}

.tba-pencil::before {
  @extend %collecticon-pencil;
}

.tba-trash::before {
  @extend %collecticon-trash-bin;
}

.tba-xmark::before {
  @extend %collecticon-sm-xmark;
}


/* Toolbar modifiers
   ========================================================================== */

.toolbar--small {
  .button {
    @extend .button--small;
  }

  .toolbar__label {
    font-size: 0.75rem;
  }
}

.toolbar--medium {
  .button {
    @extend .button--medium;
  }
}

.toolbar--large {
  .button {
    @extend .button--large;
  }
}
