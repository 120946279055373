/*
DARK THEME
=========================================================================
*/
$base-font-color: #fff;
$base-background-color: #242e42;
$base-alpha-color: rgba($base-font-color, 0.1);

/*
LIGHT THEME
=========================================================================
*/
//$base-font-color: #242e42;
//$base-background-color: #fff;