/* ==========================================================================
   DARK THEME CSS
*/

.button--primary-raised-dark-theme {
    @include button-variation($base-background-color, "raised", "dark");
    border: 1px solid $primary-color;
    color: $primary-color;
}

.econtrols__item {
    .form__slider-group {
        margin-top: -0.75rem;
        input {
          background: $base-background-color;
          box-shadow: inset 0 0 0 1px $link-color;
        }
      }
}

.econtrols__time-select {
    @extend .button, .button--primary-raised-dark-theme;
    min-width: 5rem;
  
    &::after {
      @extend %collecticon-sm-chevron-down;
      margin-right: -0.5rem;
    }
}
  
.econtrols__reset {
    @extend .button, .button--primary-raised-dark-theme;
  
    &::before {
      @extend %collecticon-arrow-loop;
    }
}

.form__option--custom-radio,
.form__option--custom-checkbox {
    .form__option__ui {
        background: $base-background-color;
        box-shadow: inset 0 0 0 1px $link-color;
      }
    &:hover .form__option__ui {
      box-shadow: inset 0 0 0 $base-border-width rgba($link-color, 0.40);
    }  
}

.drop__menu { 
    background-color: lighten($base-background-color, 15%);
    .drop__menu-item {
        color: $base-font-color;
        &:hover,
        &:focus {
          color: $link-color;
          background-color: lighten($base-background-color, 70%);
          opacity: 1;
        }
      }
}

.econtrols {
  .nav__link {
    color: $link-color;
    &,
    &:visited {
      color: $link-color;
    }
  
    &:hover {
      color: $base-font-color;
    }
  
    &::after {
      background: $base-font-color;
    }
  
    &--active {
      color: $base-font-color;
    }
  }
}

.rcs--light .rcs__shadow {
  background: linear-gradient(to bottom, lighten($base-background-color, 5%) 0%, rgba(255, 255, 255, 0) 100%);
}