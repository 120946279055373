/* ==========================================================================
   Vendor: react-input-range
   ========================================================================== */

/**
 * Custom styles for:
 * https://github.com/davidchin/react-input-range
 */

@import 'input-range/input-range';
